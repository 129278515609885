//====================================
// Global Imports
//====================================

// 1- Import bootstrap framework
@import "1-frameworks/index";

// 2 - Import plugins
@import "2-plugins/index";

// 3 - Import base style, custom variables and mixins
@import "3-base/index";

// 4 - Import modules
@import "4-modules/index";

// 5 - Import layout  styles
@import "5-layout/index";

//====================================
// 其他主体类
//====================================

// 一般的
body {
  overflow-x: hidden;
  font-size: 15px;
}

img {
  max-width: 100%;
}

.tofront {
  position: relative;
  z-index: 1;
}

.full-width {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
}

.c-pointer:hover {
  cursor: pointer;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

// 字体排版

.display-3 {
  @include media-breakpoint-down(md) {
    font-size: 3.5rem;
  }

  @include media-breakpoint-down(sm) {
    font-size: 2rem;
  }
}

// Margins
.row.gap-y>.col,
.row.gap-y>[class*="col-"] {
  padding-top: 15px;
  padding-bottom: 15px;
}

.mt-neg5 {
  margin-top: -5rem;
}

.ml-neg5 {
  margin-left: -5rem;
}

// Heights
@include media-breakpoint-up(md) {
  .h-md-100-v {
    height: 100vh;
  }

  .h-md-100 {
    height: 100vh;
  }
}

@include media-breakpoint-only(xl) {
  .h-xl-300 {
    height: 300px;
  }

  .h-max-380 {
    max-height: 380px;
  }
}

@include media-breakpoint-only(xxl) {
  .h-xl-560 {
    max-height: 560px;
  }
}

// Mixins
@mixin button-variant-link($color) {
  color: $color;
}

// Buttons
.btn-round {
  border-radius: $round-radius !important;
}

.btn {
  @include button-size($btn-padding-y,
    $btn-padding-x,
    $font-size-base,
    $btn-line-height,
    $btn-border-radius );

  &:hover,
  &:focus {
    box-shadow: $box-shadow;
    outline: 0 !important;
  }

  .badge {
    position: absolute;
    top: -0.625rem;
    right: -0.3125rem;
  }

  position: relative;
}

.btn-lg {
  @include button-size($btn-padding-y-lg,
    $btn-padding-x-lg,
    $font-size-lg,
    $btn-line-height-lg,
    $btn-border-radius-lg );
}

.btn-sm {
  @include button-size($btn-padding-y-sm,
    $btn-padding-x-sm,
    $font-size-sm,
    $btn-line-height-sm,
    $btn-border-radius-sm );
}

.btn-link {

  &:hover,
  &:focus {
    box-shadow: none;
  }
}

@each $color, $value in $theme-colors {
  .btn-link.btn-#{$color} {
    @include button-variant-link($value);
    border-color: transparent;
  }
}

.btn-white {
  background-color: #fff;
}

// 输入框
.input-round {
  border-radius: $round-radius !important;
}

.input-group.input-round {
  input:first-child {
    border-radius: 30px 0 0 30px;
  }

  input:last-child {
    border-radius: 0px 30px 30px 0px;
  }
}

/*************************************** 导航栏 */
.navbar {
  background: rgba(255, 255, 255, 0.4);
  height: 80px;

  .navbar-brand {
    background: url("../../assets/img/index/logo.png") center no-repeat;
    background-size: contain;
    font-size: 0;
    line-height: 0;

    margin-right: 57px;
    width: 219px;
    height: 54px;
  }

  .nav-item {
    .nav-link {
      color: #333 !important;
      font-weight: 400 !important;

      &:hover {
        color: #000 !important;
      }
    }
  }

  .dropdown {
    .dropdown-item {
      font-weight: $font-weight-base;
    }

    .dropdown-menu {
      border: 0;
      text-transform: none;
      box-shadow: $box-shadow-lg;

      &:before {
        @include media-breakpoint-up(md) {
          content: "";
          top: -8px;
          position: absolute;
          left: 50px;
          border-top: 16px solid #fff;
          border-left: 16px solid #fff;
          transform: rotate(45deg);
        }
      }
    }

    &:hover .dropdown-menu {
      display: block;
    }

    .dropdown-toggle {
      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      border: none;
      border-radius: 0;
      width: 280px;

      .dropdown-title {
        font-size: 12px;
        color: #999999;
        box-sizing: border-box;
        padding-left: 24px;
        padding-top: 10px;
        margin-bottom: 8px;
      }

      .dropdown-item {
        font-size: 16px;
        color: #333333;
      }

      &::before {
        display: none !important;
      }
    }
  }

  .navbar-right {
    .nav-item {
      display: flex;
      align-items: center;
    }

    img {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }

    span {
      display: block;
      color: #333333;
      font-size: 1rem;
      line-height: 1;
    }

    .t1 {
      margin-right: 12px;
    }

    .t2 {
      color: inherit;
      font-weight: bold;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

/** H5 样式 **/
@include media-breakpoint-up(xs) {
  .navbar {
    .navbar-brand {
      zoom: calc(0.75);
    }
  }
}

// 巨幕（banner)
header {
  width: 100vw;

  .jumbotron {
    border-radius: 0;
    padding: 0;
    margin: 0;

    .display-4,
    .display-3 {
      font-weight: 600;
      font-size: 56px;
      line-height: 78px;
      margin-bottom: 20px;
    }

    .lead {
      height: 30px;
      font-weight: 400;
      font-size: 22px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 30px;
    }

    &.index {
      height: 700px;
      background: url(../../assets/img/index/banner.png) center no-repeat;
      background-size: cover;
      padding-top: 258px;

      .display-3 {
        font-weight: 600;
        font-size: 44px;
        line-height: 62px;

        &:first-child {
          margin-bottom: 10px;
        }
      }
    }

    &.customized {
      height: 460px;
      background: url(../../assets/img/bg-customized.png) center no-repeat;
      background-size: cover;
      color: #fff;
      padding-top: 122px;
      text-align: center;
    }

    &.iot {
      height: 620px;
      background: url(../../assets/img/iot/bg-jumbotron.png) center no-repeat;
      background-size: cover;
    }

    &.paay {
      height: 620px;
      background: url(../img/pay/bg.png) 0 0 no-repeat;
      background-size: cover;
    }

    &.store {
      background: url(../img/store/bg-jumbotron.png) 0 0 no-repeat;
      background-size: cover;
      height: 620px;
    }

    &.sass {
      height: 620px;
      background: url(../../assets/img/sass/bg-jumbotron.png) center no-repeat;
      background-size: cover;
    }

    &.promote {
      height: 620px;
      background: url(../../assets/img/promoto/bg-jumbotron.png) center no-repeat;
      background-size: cover;
    }

    &.divide {
      height: 620px;
      background: url(../../assets/img/divide/bg-jumbotron.png) center no-repeat;
      background-size: cover;
    }
  }

  @include media-breakpoint-up(xs) {
    .jumbotron {
      max-height: 450px;
      padding: 0 !important;

      .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

section {
  .container {
    padding: 0;
  }

  .i-title {
    font-weight: 500;
    font-size: 42px;
    color: #333333;
    line-height: 59px;
    text-align: center;
    margin-bottom: 40px;
  }

  .i-desc-tips {
    height: 28px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #666666;
    line-height: 28px;
    margin-bottom: 40px;
    text-align: center;
  }

  .card {
    border: none;
    border-radius: 0;
  }

  /** 首页 */
  .i-can {
    height: 650px;

    .container {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .card {
      margin-right: 20px;
      border: none;
      border-radius: 0;

      width: 270px;
      height: 350px;
      background: #f4f8fb;
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding: 40px 20px;
      cursor: default;

      &:hover {
        background: linear-gradient(180deg, #3f79ff 0%, #45beff 100%);

        .c-title,
        .c-description {
          color: rgba(255, 255, 255, 1);
        }

        .c-icon .c-icon-hover {
          display: inline !important;
        }
      }

      .c-title {
        text-align: center;
        height: 40px;
        font-weight: 600;
        font-size: 28px;
        color: #333333;
        line-height: 40px;
        margin-bottom: 20px;
      }

      .c-description {
        font-size: 18px;
        color: #838383;
        line-height: 25px;
        text-align: center;
        margin-bottom: 30px;
        min-height: 100px;
      }

      .c-icon {
        position: relative;
        width: 80px;
        height: 80px;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }

        .c-icon-hover {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 99;
          display: none;
        }
      }
    }

    .card:last-child {
      margin-right: 0;
    }
  }

  .i-industry {
    height: 1069px;
    background: rgba(246, 249, 255, 1);

    .container {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    .item {
      position: relative;
      max-width: 290px;
      height: 270px;
      margin-bottom: 20px;
      padding: 0;

      &:nth-child(4n) {
        .card {
          margin-right: 0;
        }
      }

      &>img {
        display: block;
        width: 100%;
        max-width: 270px;
        height: 100%;
      }
    }

    .card {
      position: absolute;
      width: 270px;
      height: 270px;
      border: none;
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      margin-right: 20px;

      img {
        display: block;
        max-width: 86px;
        max-height: 86px;
        margin-bottom: 20px;
      }

      h2 {
        height: 40px;
        font-weight: 500;
        font-size: 28px;
        color: #ffffff;
        line-height: 40px;
        margin: 0;
      }
    }
  }

  .i-service {
    height: 880px;

    .container {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .item {
      margin-bottom: 20px;
      height: 280px;
      max-width: 367px;
      margin: 0 auto;

      &:nth-child(3n) {
        margin-right: 0px;
      }

      .card {
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: 0;

        img {
          display: block;
          max-width: 87px;
          max-height: 86px;
          margin-bottom: 40px;
        }

        h3 {
          height: 40px;
          font-weight: 500;
          font-size: 28px;
          color: #333333;
          line-height: 40px;
          margin-bottom: 10px;
        }

        p {
          height: 25px;
          font-size: 18px;
          color: #999999;
          line-height: 25px;
          margin: 0;
        }
      }
    }
  }

  .i-support {
    height: 454px;
    background: url(../../assets/img/index/bg-support.png) center 0 no-repeat;
    background-size: cover;

    .container {
      padding-top: 100px;
      padding-bottom: 128px;
    }

    .i-title {
      color: #fff;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;

      .card {
        max-width: 105px;
        background: transparent;

        img {
          display: block;
          width: 60px;
          height: 60px;
          margin: 0 auto 12px;
        }

        h4 {
          height: 37px;
          font-weight: 600;
          font-size: 26px;
          color: #ffffff;
          line-height: 37px;
        }
      }
    }
  }

  .i-contact {
    height: 340px;
    background: url(../../assets/img/index/bg-contract.png) center 0 no-repeat;
    background-size: cover;

    .container {
      padding-top: 100px;
      padding-bottom: 100px;
      color: #ffffff;
    }

    .i-c-left {
      h4 {
        font-weight: 600;
        font-size: 40px;
        line-height: 56px;
        margin-bottom: 20px;
      }

      p {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .i-c-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        margin: 0;
        margin-right: 20px;
        font-size: 16px;
        line-height: 22px;
      }

      img {
        display: block;
        width: 140px;
        height: 140px;
      }
    }
  }

  /** 定制 */
  .c-service {
    padding: 100px 0;

    .container {
      max-width: 1140px;
      padding: 0;
    }

    .nav-box {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }

    .nav {
      width: 530px;
      background-color: #ffffff;

      .nav-link {
        position: relative;
        height: 110px;
        box-sizing: border-box;
        padding-left: 74px;
        padding-top: 20px;
        color: #333333;
        border-radius: 0;

        &.active {
          background-color: #2d87ff;

          i,
          h4,
          p {
            color: #fff;
          }

          i {
            opacity: 0.6;
          }

          p {
            opacity: 0.7;
          }
        }

        i {
          position: absolute;
          top: 15px;
          left: 20px;
          width: 44px;
          height: 50px;
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 50px;
          color: #b2c0d9;
        }

        h4 {
          height: 40px;
          font-weight: 500;
          font-size: 28px;
          margin-bottom: 8px;
          line-height: 40px;
        }

        p {
          height: 22px;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    .tab-content {
      width: 610px;
      height: 550px;
      background-color: #f5faff;

      .tab-pane {
        box-sizing: border-box;
        padding: 30px 40px;
      }

      .c-s-title {
        position: relative;
        height: 45px;
        font-weight: 500;
        font-size: 32px;
        color: #333333;
        line-height: 45px;
        margin-bottom: 44px;

        &::after {
          position: absolute;
          left: 4px;
          bottom: -15px;
          background: url(../img/customized/c-arrow.png) 0 0 no-repeat;
          background-size: 100%;
          width: 25px;
          height: 9px;
          content: "";
        }
      }

      .c-s-content {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        li {
          width: 230px;
          height: 60px;
          background: #e0eeff;
          border-radius: 6px;
          line-height: 60px;
          margin-bottom: 30px;
          text-align: center;

          span {
            font-weight: 500;
            font-size: 20px;
            color: #2c85fc;
            line-height: 28px;
          }
        }
      }
    }

    .no-gutters {
      margin-bottom: 100px;
    }

    .c-item {
      width: 100%;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        max-width: 50px;
        max-height: 50px;
      }

      span {
        height: 40px;
        font-weight: 500;
        font-size: 28px;
        color: #ffffff;
        line-height: 40px;
        margin-left: 17px;
      }

      &.c-item-1 {
        background: linear-gradient(90deg, #2768ff 0%, #45abff 100%);
      }

      &.c-item-2 {
        background: linear-gradient(90deg, #2ec796 0%, #2ecfab 100%);
      }

      &.c-item-3 {
        background: linear-gradient(270deg, #8bd2ff 0%, #1eaaff 100%);
      }

      &.c-item-4 {
        background: linear-gradient(90deg, #213152 0%, #5d698a 100%);
      }
    }
  }

  .c-case {
    height: 630px;
    background-color: #f5faff;
    padding: 60px 0 70px;
    box-sizing: border-box;

    .container {
      max-width: 1140px;
      padding: 0;
    }

    .c-case-box {
      display: flex;
      align-items: center;
      max-width: 1140px;

      img {
        display: block;
        width: 400px;
        height: 400px;
      }

      .c-case-content {
        text-align: left;
        width: 740px;
        height: 400px;
        background: #fff;
        box-sizing: border-box;
        padding: 40px;

        h4 {
          height: 45px;
          font-weight: 500;
          font-size: 32px;
          color: #333333;
          line-height: 45px;
          margin-bottom: 10px;
        }

        .c-case-tag {
          display: flex;
          margin-bottom: 24px;

          span {
            display: block;
            text-align: center;

            width: 68px;
            height: 36px;
            background: #eef7ff;
            border-radius: 6px;
            margin-right: 10px;
            line-height: 36px;

            color: #2c85fc;
          }
        }

        dl {

          dt,
          dd {
            height: 22px;
            font-size: 16px;
            color: #666666;
            line-height: 22px;
            margin-bottom: 10px;
          }

          dt {
            font-weight: inherit;
          }
        }
      }
    }
  }

  // 智能支付
  .p-one {
    box-sizing: border-box;
    padding: 60px 0 0;

    .col {
      max-width: 270px;
    }

    .item {
      position: relative;
      width: 270px;
      height: 90px;
      background: url(../img/pay/bg-item.png) center no-repeat;
      background-size: cover;
      box-sizing: border-box;
      padding: 20px;
      padding-left: 86px;

      img {
        position: absolute;
        left: 20px;
        top: 20px;
        max-width: 46px;
        max-height: 46px;
      }

      h4 {
        height: 28px;
        font-weight: 500;
        font-size: 20px;
        color: #2a2e3b;
        line-height: 28px;
        margin-bottom: 2px;
      }

      .tips {
        font-weight: 400;
        font-size: 12px;
        color: #7487a7;
        line-height: 17px;
      }
    }
  }

  .p-two {
    padding: 100px 0;

    container: {
      width: 1200px;
    }

    .nav-box {
      border-bottom: 2px solid #e6eaf1;
      padding-bottom: 8px;

      .nav {
        display: flex;
      }

      .nav-link {
        flex: 1;
        position: relative;
        padding: 0;
        text-align: center;

        height: 25px;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        cursor: pointer;

        &.active {
          color: #008ae8;

          &::after {
            position: absolute;
            left: 50%;
            margin-left: -19px;
            bottom: -11px;
            width: 38px;
            height: 4px;
            background: #008ae8;
            content: "";
          }
        }
      }
    }

    .tab-content {
      .tab-pane {
        box-sizing: border-box;
        padding-top: 70px;
        .h-100{
          box-sizing: border-box;
          padding-bottom: 120px;
        }
      }

      .tab-pane-left {
        box-sizing: border-box;
        margin-left: 30px;
        margin-top: 86px;
        min-height: 344px;

        h4 {
          height: 40px;
          font-weight: 500;
          font-size: 28px;
          color: #333333;
          line-height: 40px;
          margin-bottom: 5px;
        }

        .desc {
          height: 22px;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          line-height: 22px;
          margin-bottom: 50px;
        }

        .s-title {
          height: 20px;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
        }

        .btn-group {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding-top: 10px;
          height: 50px;

          .btn {
            box-sizing: border-box;
            padding: 0;
            margin-right: 20px;
            width: 120px;
            height: 48px;
            line-height: 48px;
            background: #ffffff;
            border-radius: 6px;
            border: 1px solid #666666;

            text-align: center;
            font-weight: 400;
            font-size: 20px;
            color: #666666;
          }

          .btn-none {
            height: 28px;
            font-weight: 400;
            font-size: 20px;
            color: #666666;
            line-height: 28px;
          }

          .btn-more {
            width: 32px;
            height: 4px;
            background: url(../img/pay/btn-more.png) 0 0 no-repeat;
            background-size: cover;
          }
        }
      }

      .tab-pane-right {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 100px;

        img {
          display: block;
        }

        .img-1 {
          max-width: 246px;
          max-height: 344px;
        }

        .img-2 {
          max-width: 374px;
          max-height: 287px;
        }

        .img-3 {
          max-width: 388px;
          max-height: 307px;
        }
      }
    }

    .p-two-end {
      .i-title {
        margin-bottom: 40px;
      }

      .c-item {
        max-width: 400px;
        height: 230px;

        box-sizing: border-box;
        padding: 75px 60px;
        display: none;

        img {
          max-width: 34px;
          max-height: 32px;
          margin-bottom: 12px;
        }

        span {
          display: block;
          height: 40px;
          font-weight: 400;
          font-size: 28px;
          color: #333333;
        }

        &:first-child {
          background: #f3f3f3;
        }

        &:last-child {
          background: #008ae8;
        }

        &.active {
          top: 0;
          left: 0;
          padding: 30px 0 0 60px;
          color: #ffffff;
          display: block;

          dt {
            height: 40px;
            font-weight: 600;
            font-size: 28px;
            line-height: 40px;
            margin-bottom: 10px;
          }

          dd {
            position: relative;
            height: 28px;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            box-sizing: border-box;
            padding-left: 16px;

            &::before {
              position: absolute;
              left: 0;
              top: 11px;
              width: 6px;
              height: 6px;
              border-radius: 3px;
              background-color: #fff;
              content: "";
            }
          }
        }
      }
    }
  }

  // 门店
  .s-one {
    height: 1467px;
    box-sizing: border-box;

    .i-title {
      margin-bottom: 40px;
      padding-top: 100px;
    }

    .s-content-1 {
      width: 1080px;
      height: 104px;
      margin: 0 auto;

      .item {
        margin: 0 auto;
        max-width: 80px;

        img {
          display: block;
          width: 66px;
          height: 66px;
          margin: 0 auto 10px;
        }

        h4 {
          height: 28px;
          font-weight: 400;
          font-size: 20px;
          color: #333333;
          line-height: 28px;
        }
      }
    }

    .container {
      width: 1140px;
    }

    .nav-box {
      width: 270px;
      margin-right: 50px;

      .nav-link {
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;

        &.active {
          background-color: #008ae8;
          color: #fff;
          justify-content: center;

          img {
            display: none;

            &.active {
              display: block;
            }
          }

          h4 {
            color: inherit;
          }
        }

        img {
          display: block;
          width: 34px;
          height: 34px;
          margin-right: 10px;

          &.active {
            display: none;
          }
        }

        h4 {
          font-weight: 600;
          font-size: 24px;
          color: #333333;
          line-height: 34px;
          margin: 0;
        }
      }
    }

    .tab-content {
      width: 820px;

      img {
        display: block;
        width: 820px;
        height: 500px;
      }
    }

    .s-content-2 {
      .col:last-child {
        .item::after {
          display: none;
        }
      }

      .item {
        margin: 0 auto;
        width: 110px;
        height: 110px;
        background-color: #f4f8fb;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        &::after {
          position: absolute;
          left: 140px;
          top: 50%;
          margin-top: -7px;

          display: block;
          width: 37px;
          height: 14px;
          background: url(../img/store/s-step-arrow.png) 0 0 no-repeat;
          background-size: cover;
          content: "";
        }

        img {
          display: block;
          width: 66px;
          height: 66px;
        }
      }

      h4 {
        height: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #000000;
        line-height: 28px;
        text-align: center;
      }
    }
  }

  .s-two {
    height: 220px;
    background-color: #008ae8;
    box-sizing: border-box;
    padding: 40px 0;

    .s-two-item {
      box-sizing: border-box;
      padding-left: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .s-two-left {
        display: block;
        width: 140px;
        height: 140px;
        margin-right: 20px;
      }

      .s-two-right {
        width: 340px;

        h4,
        p {
          margin: 0;
        }

        h4 {
          height: 33px;
          font-weight: 500;
          font-size: 24px;
          margin-bottom: 5px;
        }

        div {
          margin-bottom: 10px;
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          text-align: justify;
        }

        p {
          height: 25px;
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          opacity: 0.7;
        }
      }
    }
  }

  .s-three {
    height: 466px;
    background: url(../img/store/bg@2x.png) center no-repeat;
    background-size: cover;
    box-sizing: border-box;
    padding: 80px 0;

    .s-three-content {
      .col {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .s-three-item {
        width: 185px;
        height: 96px;
        background: #ffffff;
        border-radius: 6px;
        text-align: center;
        line-height: 96px;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: block;
          height: 33px;
          font-weight: 600;
          font-size: 24px;
          color: #333333;
          line-height: 33px;
        }
      }
    }
  }

  .s-four {
    box-sizing: border-box;
    padding: 100px 0;

    .swiper {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      overflow-x: hidden;
      z-index: 1;
    }

    .swiper-slide {
      width: 400px !important;
      height: 300px;
      margin-right: 30px;

      &:last-child {
        margin: 0;
      }

      img {
        display: block;
        width: 400px;
        height: 300px;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      box-shadow: 0px 0px 4px 0px rgba(196, 196, 196, 0.5);
      background-color: #FFFFFF;
      background-size: 14px 24px;
    }

    // .swiper-button-prev {
    //   top: 340px;
    //   left: calc(50% - 70px);
    // }

    // .swiper-button-next {
    //   top: 340px;
    //   left: calc(50% + 20px);
    // }


  }

  // 物联网
  .i-one {
    box-sizing: border-box;
    padding: 100px 0;

    .container {
      max-width: 1140px;
    }

    .card {
      .card-top {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 178px;
        padding: 46px 0;
        box-sizing: border-box;

        img {
          display: block;
          max-width: 86px;
          max-height: 86px;
          margin: auto;
        }
      }

      .card-body {
        padding: 0;

        h5 {
          font-weight: 600;
          font-size: 28px;
          color: #333333;
          line-height: 40px;
          margin-bottom: 10px;
          text-align: center;
        }

        span {
          display: block;
          width: 250px;
          text-align: center;
          font-weight: 400;
          font-size: 18px;
          color: #666666;
          line-height: 26px;
        }
      }
    }
  }

  .i-two {
    padding-bottom: 70px;

    .container {
      max-width: 1140px;
    }

    .img-card-top {
      width: 367px;
      height: 200px;
      margin-bottom: 18px;
    }

    .card-body {
      padding: 0 0 30px;

      h5 {
        font-weight: 500;
        font-size: 22px;
        color: #333333;
        line-height: 30px;
        margin-bottom: 10px;
      }

      span {
        display: block;
        width: 306px;
        font-weight: 400;
        font-size: 16px;
        color: #666666;
        line-height: 22px;
        text-align: left;
        font-style: normal;
      }
    }
  }

  // sass
  .sass-one {
    .card-top {
      img {
        max-width: 86px;
        max-height: 82px;
      }
    }

    .card-body {
      h5 {
        height: 40px;
        font-weight: 600;
        font-size: 28px;
        color: #333333;
        line-height: 40px;
      }
    }
  }

  .sass-two {
    .container {
      max-width: 1140px;
    }

    .sass-two-box {
      margin-bottom: 100px;

      img {
        width: 550px;
        height: 280px;
      }
    }

    .sass-two-intro {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 280px;

      .display-4 {
        font-weight: 500;
        font-size: 42px;
        color: #333333;
        line-height: 60px;
        margin-bottom: 20px;
      }

      .lead {
        font-weight: 400;
        font-size: 22px;
        color: #333333;
        line-height: 30px;
      }
    }
  }

  // 展业
  .promote-one {
    padding-top: 100px;

    .container {
      max-width: 1140px;
    }

    .i-title {
      height: 59px;
      font-weight: 500;
      font-size: 42px;
      color: #333333;
      line-height: 59px;
    }

    .p-intro {
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
      margin-bottom: 100px;
    }
  }

  //
  .d-one {
    box-sizing: border-box;
    padding: 100px 0;

    .container {
      max-width: 1140px;
    }

    .d-content {
      margin: 0 -5px;

      .d-one-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 373px;
        margin: 0 5px;

        .box-top {
          display: flex;
          width: 373px;
          height: 80px;
          background: url(../img/divide/tab-bg.png) 0 0 no-repeat;
          box-sizing: border-box;
          padding: 15px 20px;
          display: flex;
          align-items: center;

          i {
            font-weight: 600;
            font-size: 36px;
            color: #aebed9;
            line-height: 50px;
            margin-right: 10px;
            font-style: normal;
          }

          h4 {
            font-weight: 500;
            font-size: 28px;
            color: #2a2e3b;
            margin: 0;
          }
        }

        .box-body {
          box-sizing: border-box;
          padding: 10px 20px;

          width: 373px;
          height: 70px;
          background: #ffffff;
          border: 1px solid #eeeeee;

          span {
            font-weight: 400;
            font-size: 14px;
            color: #7f848c;
            line-height: 20px;
          }
        }
      }
    }
  }

  .d-two {
    box-sizing: border-box;
    margin-bottom: 100px;

    .container {
      max-width: 1200px;
    }

    .nav {
      height: 55px;
      display: flex;

      .nav-link {
        position: relative;
        flex: 1;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        padding-top: 0;

        &.active {
          color: #008ae8;
          background: transparent;

          &::after {
            position: absolute;
            bottom: 20px;
            left: 50%;
            margin-left: -19px;

            width: 38px;
            height: 4px;
            background: #008ae8;

            content: "";
          }
        }
      }
    }

    .tab-content {
      .tab-pane {
        height: 490px;
      }

      .d-two-box {
        display: flex;
        width: 100%;
        height: 490px;

        .d-box-left {
          flex: 1;
          box-sizing: border-box;
          padding: 40px 30px;
          background: #edf5fe;

          h4 {
            width: 128px;
            height: 45px;
            font-weight: 500;
            font-size: 32px;
            color: #333333;
            line-height: 45px;
          }

          .desc {
            height: 25px;
            font-weight: 400;
            font-size: 18px;
            color: #7f848c;
            line-height: 25px;
            margin-bottom: 30px;
          }

          img {
            display: block;
            width: 486px;
            height: 300px;
          }
        }

        .d-img-right {
          display: block;
          width: 560px;
          height: 490px;
        }
      }
    }
  }
}

// Backgrounds
.bg-black {
  background-color: $black;
}

.overlay {
  position: relative;

  .container {
    position: relative;
  }

  &:before {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
  }
}

.overlay-black {
  &:before {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.overlay-blue {
  &:before {
    background-color: rgba(23, 29, 90, 0.5);
  }
}

.overlay-red {
  &:before {
    background: linear-gradient(0deg,
        rgba(44, 44, 44, 0.2),
        rgba(224, 23, 3, 0.6));
  }
}

.overlay-blue {
  &:before {
    background-color: rgba(23, 29, 90, 0.5);
  }
}

// SVG fills

@mixin fill-variant($parent, $color) {
  #{$parent} {
    fill: $color !important;
  }
}

@each $color, $value in $theme-colors {
  @include fill-variant(".bg-#{$color}", $value);
}

// Cards
.card {
  .date {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    background: $danger;
    width: 55px;
    height: 55px;
    padding: 12.5px 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
    -webkti-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .day {
      font-size: 16px;
      line-height: 1;
    }

    .month {
      font-size: 11px;
      text-transform: uppercase;
    }
  }

  a:hover {
    text-decoration: none;
    color: $primary;
  }
}

.card-pricing .card ul li {
  margin-bottom: 1.5rem;
}

// Icons
.iconbox {
  border: 1px solid;
  text-align: center;
  display: inline-block;
}

.iconbox.iconsmall {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 1rem;
}

.iconbox.iconmedium {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 1.8rem;
}

.iconbox.iconlarge {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 2.2rem;
}

// Alerts
@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant(theme-color-level($color, $alert-bg-level),
      theme-color-level($color, $alert-border-level),
      #fff);
  }
}

// Lists

ul.list-unstyled li {
  margin-bottom: 0.3rem;
}

// Login

@include media-breakpoint-up(md) {
  .loginarea {
    z-index: 1111;
  }
}

// Article
article {
  line-height: 1.7;

  p,
  pre,
  figure,
  img {
    margin-bottom: 2rem;
  }

  blockquote {
    border-left: 7px solid $info;
    padding-left: 30px;
    margin-left: 30px;
    font-style: italic;
  }
}

// AOS
[data-aos] {
  pointer-events: none;

  &.aos-animate {
    pointer-events: auto;
  }
}

// Footer
footer {
  height: 370px;
  background-color: #000;

  .row {
    padding-top: 60px;
    height: 316px;

    .f-t-left {
      img {
        width: 218px;
        height: 54px;
        display: block;
        margin-bottom: 30px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        opacity: 0.6;
      }

      a {
        font-weight: 600;
        font-size: 26px;
        color: #ffffff;
        line-height: 37px;

        &:hover,
        &:active {
          text-decoration: none;
          color: #ffffff;
        }
      }
    }

    .row {
      padding: 0;
      height: auto;

      dl {
        padding-left: 80px;

        dt {
          height: 28px;
          font-weight: 600;
          font-size: 20px;
          color: #ffffff;
          line-height: 28px;
          margin-bottom: 20px;
        }

        dd {
          height: 22px;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          line-height: 22px;
          opacity: 0.6;
          margin-bottom: 20px;

          a {
            color: inherit;

            &:hover,
            &:active {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .copyright {
    height: 54px;
    border-top: 1px solid rgba(242, 242, 242, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 1rem;
      color: rgba(146, 146, 146, 0.6);
      line-height: 1.38rem;
    }
  }
}

// DOCS FOR DEMO ONLY

@include media-breakpoint-up(lg) {
  .fixedsidebar {
    top: 0px;
    position: fixed;
    z-index: 1050;
    left: 0;
    overflow-y: overlay;
    width: 260px;
    height: 100%;
    padding: 0 0.5rem 0.5rem 1.5rem;
    border-right: 1px solid #e9ecef;
    background-color: #fff;
    padding-bottom: 50px;
  }

  .content-withfixedsidebar {
    margin-left: 260px;

    .navbar.fixed-top {
      margin-left: 260px;
    }
  }
}

#docsarea {
  h1 {
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 600;
  }

  h2 {
    text-transform: uppercase;
    font-size: 1.4rem;
  }

  h1,
  h2 {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  h1+h2 {
    padding-top: 0;
  }
}

/* JUST SOME STYLES FOR PRE CODES */
code {
  font-size: 14px;
  display: inline-block;
  line-height: 1.5;
}

.code-toolbar {
  position: relative;
}

.code-toolbar .toolbar-item a {
  position: absolute;
  top: -10px;
  right: -10px;
  color: #ffffff;
  background: #333;
  padding: 0 8px;
  border-radius: 30px;
  font-size: 13px;
  cursor: pointer;
}

.code-toolbar .toolbar-item a:hover,
.code-toolbar .toolbar-item a:focus {
  background: #ff5684;
  color: #fff;
}

#docsarea .alert-orange {
  color: rgba(255, 255, 255, 0.83);
}

#docsarea .alert-orange code {
  color: #b73b3b;
}

#docsarea .alert-orange a {
  color: inherit;
  border-bottom: 1px dashed;
}

ul.ascii,
ul.ascii ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

ul.ascii li {
  margin: 0;
  padding: 0;
}

/* level 1 */
ul.ascii>li::before {
  content: "";
}

/* level 2 */
ul.ascii>li>ul>li::before {
  content: "├──\00a0";
}

ul.ascii>li>ul>li:last-child::before {
  content: "└──\00a0";
}

/* level 3 */
ul.ascii>li>ul>li>ul>li::before {
  content: "│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li::before {
  content: "\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0└──\00a0";
}

/* level 4 */
ul.ascii>li>ul>li>ul>li>ul>li::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

/* level 5 */
ul.ascii>li>ul>li>ul>li>ul>li>ul>li::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li>ul>li>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li>ul>li>ul>li:last-child>ul>li::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li>ul>li:last-child>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li>ul>li::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li:last-child>ul>li::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li:last-child>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li>ul>li::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li:last-child>ul>li::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li:last-child>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li>ul>li::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

/* level 6 */
ul.ascii>li>ul>li>ul>li>ul>li>ul>li>ul>li::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li>ul>li>ul>li>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li>ul>li>ul>li>ul>li:last-child>ul>li::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li>ul>li>ul>li:last-child>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li>ul>li>ul>li:last-child>ul>li>ul>li::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li>ul>li:last-child>ul>li>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li>ul>li>ul>li:last-child>ul>li:last-child>ul>li::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li>ul>li:last-child>ul>li:last-child>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li>ul>li>ul>li::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li>ul>li>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li>ul>li:last-child>ul>li::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li>ul>li:last-child>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li:last-child>ul>li>ul>li::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li:last-child>ul>li>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child::before {
  content: "│\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li>ul>li>ul>li::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li>ul>li>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li>ul>li:last-child>ul>li::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li>ul>li:last-child>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li:last-child>ul>li>ul>li::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li:last-child>ul>li>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li:last-child>ul>li:last-child>ul>li::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li>ul>li:last-child>ul>li:last-child>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li>ul>li>ul>li::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li>ul>li>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li>ul>li:last-child>ul>li::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li>ul>li:last-child>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li>ul>li::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0│\00a0\00a0\00a0└──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0├──\00a0";
}

ul.ascii>li>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child::before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0└──\00a0";
}

// END DOCS FOR DEMO ONLY
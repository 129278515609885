@import 'layout';
@import 'header';
@import 'footer';
@import 'containers';
@import 'columns';
@import 'extends';

.navbar-brand{
    width: 219px;
    height: 54px;
    background: url('assets/img/index/logo.png') center no-repeat;
    font-size: 0;
    line-height: 0;
}